<template>
  <div>
    Orders By Recency
  </div>
</template>

<script>
export default {
  name: 'OrdersByRecency',
}
</script>

<style scoped>

</style>
